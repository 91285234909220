import Modal from 'react-modal'
import { ReactComponent as Close } from '../data/Close.svg'

Modal.setAppElement('#root')

type Props = {
  isOpen: boolean
  handleClose: () => void
  darkMode: boolean
  styles: any
}

export const InfoModal = ({ isOpen, handleClose, darkMode, styles }: Props) => (
  <Modal isOpen={isOpen} onRequestClose={handleClose} style={styles} contentLabel="Game Info Modal">
    <div className={`h-full ${darkMode ? 'dark' : ''}`}>
      <button
        className="absolute top-4 right-4 rounded-full nm-flat-background dark:nm-flat-background-dark text-primary dark:text-primary-dark p-1 w-6 h-6 sm:p-2 sm:h-8 sm:w-8 hover:nm-inset-background dark:hover:nm-inset-background-dark"
        onClick={handleClose}
      >
        <Close />
      </button>
      <div className="h-full flex flex-col items-center justify-center max-w-[390px] mx-auto pt-9 text-primary dark:text-primary-dark">
        <div className="flex-1 w-full sm:text-base text-sm border-b border-slate-400 mb-4">
          <h1 className="text-center sm:text-3xl text-2xl">How to play</h1>
          <ul className="list-disc pl-5 block sm:text-base text-sm">
            <li className="mt-6 mb-2">You have 6 guesses to guess the correct word.</li>
            <li className="mb-2">You can guess any valid word.</li>
            <li className="mb-2">
              After each guess, each letter will turn green, yellow, or gray.
            </li>
          </ul>
          <div className="mb-3 mt-8 flex items-center">
            <span className="font-asl text-xl nm-inset-n-green text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full">
              W
            </span>
            <span className="mx-2">=</span>
            <span>Correct letter, correct spot</span>
          </div>
          <div className="mb-3">
            <span className="font-asl text-xl nm-inset-yellow-500 text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full">
              W
            </span>
            <span className="mx-2">=</span>
            <span>Correct letter, wrong spot</span>
          </div>
          <span className="font-asl text-xl nm-inset-n-gray text-gray-50 inline-flex items-center justify-center text-3x w-10 h-10 rounded-full">
            W
          </span>
          <span className="mx-2">=</span>
          <span>Wrong letter</span>
        </div>
        <div className="flex flex-col items-center text-sm">
          <div className="mb-4">
            <a
              className="underline"
              href="https://ko-fi.com/s/afbfa7ae55"
              target="_blank"
              rel="noreferrer"
            >
              ASL Font
            </a>{' '}
            by{' '}
            <a
              className="underline"
              href="https://daxterslater.com/"
              target="_blank"
              rel="noreferrer"
            >
              Daxter Slater
            </a>{' '}
            👌
          </div>
          <div className="mb-4">
            Forked from{' '}
            <a
              href="https://octokatherine.github.io/word-master/"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Word Master
            </a>
            , which was inspired by{' '}
            <a
              href="https://www.powerlanguage.co.uk/wordle/"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Wordle
            </a>
            💛
          </div>
        </div>
      </div>
    </div>
  </Modal>
)
